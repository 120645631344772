import React, { useEffect, useState } from "react";
import { fetchJobCoachItems } from "./JobCoachListRepository";
import { Box, Typography, Fab, Button, Card } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { COMPANY_CREATE_ROUTE } from "../../Routes";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setJobCoaches } from "../../reducers/jobCoachListPageSlice";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import MaijaContainer from "../../components/MaijaContainer";
import { setCompanyCreateSelectedTab } from "../../reducers/companyCreatePageSlice";
import { CompanyCreatePageTab } from "../company-create/CompanyCreatePage";
import { useTranslation } from "react-i18next";
import { SupervisedUserCircle } from "@mui/icons-material";
import JobCoachList from "../../components/JobCoachList";

const JobCoachesListPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const jobCoaches = useAppSelector((state) => state.jobCoachListPageSlice.jobCoaches);

  useEffect(() => {
    setIsLoading(true);
    fetchJobCoachItems()
      .then((data) => dispatch(setJobCoaches(data)))
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  if (isLoading && jobCoaches.length === 0) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaLoadingPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  if (jobCoaches.length === 0) {
    return (
      <MaijaContainer>
        <JobCoachesListEmptyState />
      </MaijaContainer>
    );
  }

  return (
    <MaijaContainer>
      <Fab
        variant="extended"
        color="secondary"
        style={{
          position: "fixed",
          bottom: "60px",
          right: "60px",
        }}
        onClick={() => {
          dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.JobCoachTab));
          navigate(COMPANY_CREATE_ROUTE.path);
        }}
      >
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("jobCoachesList.addJobCoach")}</Typography>
      </Fab>

      <Typography variant="h4" gutterBottom sx={{ ml: 5 }}>
        {t("jobCoachesList.yourJobCoaches")}
      </Typography>

      <Box sx={{ my: 2, mb: 30 }}>
        <JobCoachList jobCoaches={jobCoaches} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </Box>
    </MaijaContainer>
  );
};

export const JobCoachesListEmptyState = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreateJobCoachButton = () => {
    dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.JobCoachTab));
    navigate(COMPANY_CREATE_ROUTE.path);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Card sx={{ m: 15, p: 20 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SupervisedUserCircle style={{ fontSize: 150 }} sx={{ mb: 5 }} />
          <Typography variant="h4" gutterBottom>
            {t("jobCoachesList.noJobCoaches")}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleCreateJobCoachButton} sx={{ m: 5 }}>
            {t("jobCoachesList.createJobCoach")}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default JobCoachesListPage;
