import { ApplicantDetails } from "../../types/ApplicantDetails";
import { Box, Typography, Divider, FormControlLabel, Switch, Grid } from "@mui/material";
import MaijaEmptyState from "../../components/MaijaEmptyState";
import { formatDateToYearMonthName } from "../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks";
import { applicantDetailsPageSlice } from "../../reducers/applicantDetilsPageSlice";
import { verifyEducation } from "./ApplicantsDetailRepository";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useCallback, useMemo } from "react";

export interface ExperiencesProps {
  applicantDetails: ApplicantDetails;
}

export const ExperiencesCV: React.FC<ExperiencesProps> = ({ applicantDetails }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const showEmptyState = applicantDetails.workExperiences.length === 0 && applicantDetails.educations.length === 0;

  const sortedWorkExperiences = useMemo(() => {
    return [...applicantDetails.workExperiences].sort((a, b) => {
      const dateA = a.toDate ? new Date(a.toDate) : new Date();
      const dateB = b.toDate ? new Date(b.toDate) : new Date();
      return dateB.getTime() - dateA.getTime();
    });
  }, [applicantDetails.workExperiences]);

  const sortedEducations = useMemo(() => {
    return [...applicantDetails.educations].sort((a, b) => {
      const dateA = a.toDate ? new Date(a.toDate) : new Date();
      const dateB = b.toDate ? new Date(b.toDate) : new Date();
      return dateB.getTime() - dateA.getTime();
    });
  }, [applicantDetails.educations]);

  const handleEducationVerifiedChange = useCallback(
    async (educationId: string, isVerified: boolean) => {
      try {
        const success = await verifyEducation(applicantDetails.applicantId, educationId, isVerified);
        if (!success) {
          throw new Error("Verification failed");
        }
        const updatedEducations = applicantDetails.educations.map((education) =>
          education.id === educationId ? { ...education, verified: isVerified } : education,
        );

        const updatedApplicantDetails = {
          ...applicantDetails,
          educations: updatedEducations,
        };

        dispatch(applicantDetailsPageSlice.actions.setApplicant(updatedApplicantDetails));
        if (isVerified) {
          dispatch(
            showSnackbar({
              message: t("applicantDetails.educationVerified"),
            }),
          );
        }
      } catch (error) {
        dispatch(
          showSnackbar({
            message: t("generic.error"),
            severity: "error",
          }),
        );
        console.error("Error verifying education:", error);
      }
    },
    [applicantDetails, dispatch, t],
  );

  return (
    <Box display="flex" flexDirection="column" width="100%" sx={{ my: 4 }}>
      {showEmptyState && <MaijaEmptyState message={t("applicantDetails.noExperiences")} />}

      <Typography sx={{ mb: 2 }} variant="h5" fontWeight="bold">
        {t("applicantDetails.workExperiences")}
      </Typography>
      {sortedWorkExperiences.map((exp, index) => (
        <Box key={exp.id} mb={2}>
          <Typography variant="h6" component="span">
            {exp.position} - {exp.workplace}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {`${formatDateToYearMonthName(exp.fromDate)} - ${exp.toDate ? formatDateToYearMonthName(exp.toDate) : t("current")}`}
          </Typography>
          {exp.description && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              {exp.description}
            </Typography>
          )}
          {index < sortedWorkExperiences.length - 1 && <Divider sx={{ mt: 2 }} />}
        </Box>
      ))}

      <Typography sx={{ mb: 2, mt: 4 }} variant="h5" fontWeight="bold">
        {t("applicantDetails.educations")}
      </Typography>
      {sortedEducations.map((edu, index) => (
        <Box key={edu.id} mb={2}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h6" component="span">
                {edu.subject} - {edu.provider}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`${formatDateToYearMonthName(edu.fromDate)} - ${edu.toDate ? formatDateToYearMonthName(edu.toDate) : t("current")}`}
              </Typography>
              {edu.description && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {edu.description}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={edu.verified}
                    onChange={(event) => handleEducationVerifiedChange(edu.id, event.target.checked)}
                    name="verified"
                    color="primary"
                  />
                }
                label={t("applicantDetails.verify")}
                sx={{ ml: 2 }}
              />
            </Grid>
          </Grid>

          {index < sortedEducations.length - 1 && <Divider sx={{ mt: 2 }} />}
        </Box>
      ))}
    </Box>
  );
};
