import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/AxiosInstance";
import { Box, Button, Grid, Paper, FormControl, InputLabel, Select, MenuItem, Alert } from "@mui/material";
import { useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";

interface JobCoach {
  id: string;
  name: string;
  email: string;
  phone_number: string;
}

interface ChangeJobCoachComponentProps {
  applicantId: string;
  onJobCoachChange: (newJobCoachName: string) => void;
}

const ChangeJobCoachComponent: React.FC<ChangeJobCoachComponentProps> = ({ applicantId, onJobCoachChange }) => {
  const [jobCoaches, setJobCoaches] = useState<JobCoach[]>([]);
  const [selectedJobCoachId, setSelectedJobCoachId] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { t } = useTranslation();
  const companyId = useAppSelector((state: RootState) => state.user.user?.companyId);

  useEffect(() => {
    axiosInstance
      .get(`/job_coaches`)
      .then((response) => setJobCoaches(response.data))
      .catch(() => setErrorMessage(t("applicantDetails.errorFetchingJobCoaches")));
  }, [companyId, t]);

  const handleChangeJobCoach = () => {
    if (!selectedJobCoachId) {
      setErrorMessage(t("applicantDetails.selectJobCoach"));
      return;
    }

    axiosInstance
      .put(`/applicants/${applicantId}/job_coach`, { job_coach_id: selectedJobCoachId })
      .then(() => {
        const updatedJobCoach = jobCoaches.find((coach) => coach.id === selectedJobCoachId);
        if (updatedJobCoach) {
          onJobCoachChange(updatedJobCoach.name); // Update job coach name in ApplicantDetailsPage
        }
        setSuccessMessage(t("applicantDetails.jobCoachUpdated"));
        setErrorMessage("");
      })
      .catch(() => {
        setErrorMessage(t("applicantDetails.failedToUpdateJobCoach"));
        setSuccessMessage("");
      });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Paper elevation={3} sx={{ p: 2, mb: 4, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="job-coach-select-label">{t("applicantDetails.jobCoach")}</InputLabel>
              <Select
                labelId="job-coach-select-label"
                value={selectedJobCoachId}
                onChange={(e) => setSelectedJobCoachId(e.target.value as string)}
                label={t("applicantDetails.jobCoach")}
              >
                {jobCoaches.map((coach) => (
                  <MenuItem key={coach.id} value={coach.id}>
                    {coach.name} - {coach.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary" onClick={handleChangeJobCoach}>
              {t("generic.submit")}
            </Button>
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
          {successMessage && (
            <Grid item xs={12}>
              <Alert severity="success">{successMessage}</Alert>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default ChangeJobCoachComponent;
