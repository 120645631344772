import React, { useState, useMemo, useCallback, SyntheticEvent } from "react";
import {
  Grid,
  Autocomplete,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DomainIcon from "@mui/icons-material/Domain";
import { FilterList, SupervisedUserCircle } from "@mui/icons-material";
import { OfficeItem } from "../../types/OfficeItem";
import { JobCoachItem } from "../../types/JobCoachItem";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAnalyticsSelectedOffice, setAnalyticsSelectedJobCoach } from "../../reducers/analyticsPageSlice";
import { TFunction } from "i18next";
import { analyticsDataFields } from "./AnalyticsDataFields";
import { ClearIcon } from "@mui/x-date-pickers";

interface FilterSelectionProps {
  offices: OfficeItem[];
  jobCoaches: JobCoachItem[];
  selectedColumn: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  t: TFunction;
  filters: { [key: string]: string | null };
  handleFilterChange: (fieldName: string, value: string | null) => void;
  clearAllFilters: () => void;
}

const FilterSelectionComponent: React.FC<FilterSelectionProps> = ({
  offices,
  jobCoaches,
  selectedColumn,
  handleChange,
  t,
  filters,
  handleFilterChange,
  clearAllFilters,
}) => {
  const dispatch = useAppDispatch();
  const selectedOffice = useAppSelector((state) => state.analyticsPageSlice.selectedOffice);
  const selectedJobCoach = useAppSelector((state) => state.analyticsPageSlice.selectedJobCoach);

  const [selectedOfficeValid, setSelectedOfficeValid] = useState(true);
  const [selectedJobCoachValid, setSelectedJobCoachValid] = useState(true);

  const filteredJobCoaches = useMemo(() => {
    if (!selectedOffice) return jobCoaches;
    return jobCoaches.filter((jobCoach) => jobCoach.officeId === selectedOffice.id);
  }, [selectedOffice, jobCoaches]);

  const handleOfficeChange = useCallback(
    (_: SyntheticEvent<Element, Event>, newValue: OfficeItem | null) => {
      if (!selectedOfficeValid) setSelectedOfficeValid(newValue != null);
      dispatch(setAnalyticsSelectedOffice(newValue));
    },
    [dispatch, selectedOfficeValid],
  );

  const handleJobCoachChange = useCallback(
    (_: SyntheticEvent<Element, Event>, newValue: JobCoachItem | null) => {
      if (!selectedJobCoachValid) setSelectedJobCoachValid(newValue != null);
      dispatch(setAnalyticsSelectedJobCoach(newValue));
    },
    [dispatch, selectedJobCoachValid],
  );

  return (
    <Paper sx={{ mb: 3, p: 4 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} sx={{ width: { xs: "100%", sm: "30%" } }}>
          <Autocomplete
            fullWidth
            options={offices}
            getOptionLabel={(option) => option.name}
            value={selectedOffice}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("analyticsPage.selectOffice")}
                variant="outlined"
                helperText={!selectedOfficeValid ? t("analyticsPage.selectOffice") : ""}
                error={!selectedOfficeValid}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <DomainIcon />
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={handleOfficeChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ width: { xs: "100%", sm: "30%" } }}>
          <Autocomplete
            fullWidth
            options={filteredJobCoaches}
            getOptionLabel={(option) => option.fullName}
            value={selectedJobCoach}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("analyticsPage.selectJobCoach")}
                variant="outlined"
                helperText={!selectedJobCoachValid ? t("analyticsPage.selectJobCoach") : ""}
                error={!selectedJobCoachValid}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <SupervisedUserCircle />
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={handleJobCoachChange}
          />
        </Grid>
      </Grid>

      <Accordion sx={{ mt: 3 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="filters-content" id="filters-header">
          <FilterList
            sx={{
              mr: 3,
              fontSize: 32,
              color: "rgba(0, 0, 0, 0.54)",
              alignSelf: "center",
            }}
          />
          <Typography variant="h6">{t("analyticsPage.additionalFilters")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {analyticsDataFields
              .filter((field) => field.labelKey && field.filter)
              .map((field) => (
                <Grid item xs={12} sm={6} md={3} key={field.name}>
                  <FormControl fullWidth>
                    <InputLabel>{t(field.labelKey)}</InputLabel>
                    <Select
                      value={filters[field.name] || ""}
                      label={t(field.labelKey)}
                      onChange={(e) => handleFilterChange(field.name, e.target.value || null)}
                    >
                      <MenuItem value="">
                        <em>{t("analyticsPage.all")}</em>
                      </MenuItem>
                      {field.options.map((option) => (
                        <MenuItem key={option} value={option}>
                          {t(`analyticsPage.fields.${option}`, option)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ))}
          </Grid>

          <Button
            variant="outlined"
            startIcon={<ClearIcon />}
            color="error"
            onClick={clearAllFilters}
            sx={{ mt: 3, mb: 1 }}
          >
            {t("analyticsPage.clearFilters")}
          </Button>
        </AccordionDetails>
      </Accordion>

      <FormControl sx={{ mt: 3, width: { xs: "100%", sm: "100%" } }}>
        <InputLabel id="select-column-label">{t("analyticsPage.fields.selectColumn")}</InputLabel>
        <Select
          labelId="select-column-label"
          value={selectedColumn}
          onChange={handleChange}
          sx={{ width: "100%" }}
          label={t("analyticsPage.fields.selectColumn")}
        >
          {analyticsDataFields.map((field) => (
            <MenuItem key={field.name} value={field.name}>
              {t(field.labelKey)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
};

export default FilterSelectionComponent;
