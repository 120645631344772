import { ApplicantDetails } from "../../types/ApplicantDetails";
import { Box, Grid, Typography } from "@mui/material";
import MaijaEmptyState from "../../components/MaijaEmptyState";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { formatDateToYearMonthName } from "../../utils/DateUtils";
import { WorkExperience } from "../../types/WorkExperience";
import { Education } from "../../types/Education";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

interface TimeLineEntry {
  type: "work" | "education" | "unemployment";
  id: string;
  fromDate: Date;
  toDate: Date | null;
  title: string | null;
  subtitle: string;
  description: string | null;
}

export interface ExperiencesProps {
  applicantDetails: ApplicantDetails;
}

const sortEvents = (workExperiences: WorkExperience[], educations: Education[]): TimeLineEntry[] => {
  const combinedEvents: TimeLineEntry[] = [
    ...workExperiences.map(
      (exp): TimeLineEntry => ({
        type: "work",
        id: exp.id,
        fromDate: new Date(exp.fromDate),
        toDate: exp.toDate ? new Date(exp.toDate) : null,
        title: exp.workplace,
        subtitle: exp.position,
        description: exp.description,
      }),
    ),
    ...educations.map(
      (edu): TimeLineEntry => ({
        type: "education",
        id: edu.id,
        fromDate: new Date(edu.fromDate),
        toDate: edu.toDate ? new Date(edu.toDate) : null,
        title: edu.provider,
        subtitle: edu.subject,
        description: edu.description,
      }),
    ),
  ];

  combinedEvents.sort((a, b) => b.fromDate.getTime() - a.fromDate.getTime());

  const unemploymentPeriods = calculateUnemploymentPeriods(combinedEvents);
  combinedEvents.push(...unemploymentPeriods);

  combinedEvents.sort((a, b) => b.fromDate.getTime() - a.fromDate.getTime());

  return combinedEvents;
};

const calculateUnemploymentPeriods = (entries: TimeLineEntry[]): TimeLineEntry[] => {
  const unemploymentPeriods: TimeLineEntry[] = [];
  let latestAbsoluteToDate = new Date(0);

  for (let i = 0; i < entries.length - 1; i++) {
    const currentEntry = entries[i];
    const nextEntry = entries[i + 1];
    if (currentEntry.toDate && currentEntry.toDate > latestAbsoluteToDate) {
      latestAbsoluteToDate = currentEntry.toDate;
    }

    if (nextEntry.toDate && nextEntry.toDate < currentEntry.fromDate) {
      let isUnemployment = true;
      let latestRelativeToDate = nextEntry.toDate;

      for (let j = i + 1; j < entries.length - 1; j++) {
        const nextNextEntry = entries[j + 1];
        if (!nextNextEntry.toDate || nextNextEntry.toDate > currentEntry.fromDate) {
          isUnemployment = false;
        } else if (nextNextEntry.toDate > latestRelativeToDate) {
          latestRelativeToDate = nextNextEntry.toDate;
        }
      }

      if (!isUnemployment) continue;

      const durationDays = Math.floor(
        (currentEntry.fromDate.getTime() - latestRelativeToDate.getTime()) / (1000 * 60 * 60 * 24),
      );

      if (durationDays <= 30) continue;

      const durationMonths = Math.floor(
        (currentEntry.fromDate.getTime() - latestRelativeToDate.getTime()) / (1000 * 60 * 60 * 24 * 30),
      );

      unemploymentPeriods.push({
        type: "unemployment",
        id: `unemployment-${i}`,
        fromDate: latestRelativeToDate,
        toDate: currentEntry.fromDate,
        title: t("applicantDetails.unemployment"),
        subtitle: "",
        description:
          `${durationMonths} ` + (durationMonths > 1 ? t("applicantDetails.months") : t("applicantDetails.month")),
      });
    }
  }

  const today = new Date();

  const durationDays = Math.floor((today.getTime() - latestAbsoluteToDate.getTime()) / (1000 * 60 * 60 * 24));

  if (durationDays > 30) {
    const durationMonths = Math.floor((today.getTime() - latestAbsoluteToDate.getTime()) / (1000 * 60 * 60 * 24 * 30));

    unemploymentPeriods.push({
      type: "unemployment",
      id: `unemployment-today`,
      fromDate: latestAbsoluteToDate,
      toDate: today,
      title: t("applicantDetails.unemployment"),
      subtitle: "",
      description:
        `${durationMonths} ` + (durationMonths > 1 ? t("applicantDetails.months") : t("applicantDetails.month")),
    });
  }

  return unemploymentPeriods;
};

export const Experiences: React.FC<ExperiencesProps> = ({ applicantDetails }) => {
  const { t } = useTranslation();
  const showEmptyState =
    applicantDetails.workExperiences.length === 0 &&
    applicantDetails.educations.length === 0 &&
    applicantDetails.skills.length === 0 &&
    applicantDetails.languages.length === 0;

  const sortedEvents = sortEvents(applicantDetails.workExperiences, applicantDetails.educations);

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexDirection={"column"}
      width="100%"
      sx={{ my: 4 }}
    >
      {showEmptyState && <MaijaEmptyState message={t("applicantDetails.noExperiences")} />}

      <Timeline
        position="alternate"
        sx={{
          opacity: 1,
          transition: "opacity 0.3s ease-in-out",
          "&:hover": {
            opacity: 1,
          },
          "& .MuiTimelineItem-root:hover": {
            opacity: 1,
          },
          "& .MuiTimelineItem-root": {
            opacity: 0.6,
            transition: "opacity 0.3s ease-in-out",
          },
        }}
      >
        {sortedEvents.map((event) => (
          <TimelineItem key={event.id}>
            <TimelineOppositeContent color="text.secondary">
              {`${formatDateToYearMonthName(event.fromDate.toISOString())} - ${
                event.toDate ? formatDateToYearMonthName(event.toDate.toISOString()) : t("current")
              }`}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                color={event.type === "work" ? "primary" : event.type === "education" ? "secondary" : "error"}
              />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Box
                sx={{
                  position: "relative",
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid transparent",
                  boxSizing: "border-box",
                  "&:hover": {
                    borderColor: "primary.main",
                  },
                  "&:hover .event-description": {
                    visibility: "visible",
                    opacity: 1,
                  },
                }}
              >
                <Typography variant="h6" component="span">
                  {event.title}
                </Typography>
                {event.subtitle && <Typography>{event.subtitle}</Typography>}
                {event.description && (
                  <Typography
                    className="event-description"
                    variant="body2"
                    sx={{
                      visibility: "hidden",
                      opacity: 0,
                      transition: "visibility 0s, opacity 0.3s linear",
                    }}
                  >
                    {event.description}
                  </Typography>
                )}
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

export interface SkillsProps {
  applicantDetails: ApplicantDetails;
}

export const Skills: React.FC<SkillsProps> = ({ applicantDetails }) => {
  const { t } = useTranslation();
  return (
    <Box width="100%">
      <Typography sx={{ mt: 2, mb: 0, mx: 1 }} variant="h5" fontWeight="bold">
        {t("applicantDetails.skills")}
      </Typography>
      <Grid container direction={"row"} sx={{ m: 2 }}>
        {applicantDetails.skills.map((skill, i) => {
          const divider = i < applicantDetails.skills.length - 1 ? ", " : "";
          return (
            <Typography key={i} sx={{ mr: 1 }} variant="body1">
              {skill.name}
              {divider}
            </Typography>
          );
        })}
      </Grid>
    </Box>
  );
};

export interface LanguagesProps {
  applicantDetails: ApplicantDetails;
}

export const Languages: React.FC<LanguagesProps> = ({ applicantDetails }) => {
  const { t } = useTranslation();
  return (
    <Box width="100%">
      <Typography sx={{ mt: 2, mb: 0, mx: 1 }} variant="h5" fontWeight="bold">
        {t("applicantDetails.language")}
      </Typography>
      <Grid container direction={"row"} sx={{ m: 2 }}>
        {applicantDetails.languages.map((language, i) => {
          const divider = i < applicantDetails.languages.length - 1 ? ", " : "";
          return (
            <Typography key={i} sx={{ mr: 1 }} variant="body1">
              {language.name}
              {divider}
            </Typography>
          );
        })}
      </Grid>
    </Box>
  );
};
