import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import JobCoachDetailsModal from "./JobCoachDetailsModal";
import { UpdateJobCoachDetails } from "./JobCoachDetailsRepository";

export type JobCoachDetailsFieldValueMap = {
  [JobCoachDetailsFieldType.Email]: string;
  [JobCoachDetailsFieldType.PhoneNumber]: string;
};

export enum JobCoachDetailsFieldType {
  Email = "email",
  PhoneNumber = "phoneNumber",
}

interface JobCoachDetailsFieldProps<T extends JobCoachDetailsFieldType> {
  type: T;
  info: JobCoachDetailsFieldValueMap[T];
  leadingSlot?: JSX.Element;
  getUpdatedJobCoachDetails: (value: JobCoachDetailsFieldValueMap[T]) => UpdateJobCoachDetails | undefined;
}
const JobCoachDetailsField = <T extends JobCoachDetailsFieldType>({
  type,
  info,
  leadingSlot,
  getUpdatedJobCoachDetails,
}: JobCoachDetailsFieldProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsModalOpen(true);
  };

  const handleDialogClose = () => {
    setIsModalOpen(false);
  };

  const displayInfo = () => {
    return info;
  };

  const renderField = () => {
    switch (type) {
      case JobCoachDetailsFieldType.Email:
      case JobCoachDetailsFieldType.PhoneNumber:
        return renderTextInput(info, type, leadingSlot, displayInfo, handleDialogOpen);
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      {renderField()}
      <JobCoachDetailsModal
        type={type}
        open={isModalOpen}
        onClose={handleDialogClose}
        getUpdatedJobCoachDetails={getUpdatedJobCoachDetails}
        initialValue={info}
      />
    </Box>
  );
};

const renderTextInput = (
  info: string,
  type: JobCoachDetailsFieldType,
  leadingSlot: JSX.Element | undefined,
  displayInfo: () => string,
  handleDialogOpen: () => void,
) => {
  return (
    <Box sx={{ textAlign: "left" }} onClick={handleDialogOpen}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "&:hover span": {
            textDecoration: "underline",
          },
          "&:hover .edit-icon": {
            visibility: "visible",
          },
        }}
      >
        {leadingSlot ? leadingSlot : <></>}
        <Typography variant="subtitle1" sx={{}}>
          <Box component="span" sx={{ display: "block" }}>
            {displayInfo()}
          </Box>
        </Typography>
        <IconButton onClick={handleDialogOpen} className="edit-icon" sx={{ mx: 1, visibility: "hidden" }}>
          <Edit />
        </IconButton>
      </Box>
    </Box>
  );
};

export default JobCoachDetailsField;
