import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { UpdateJobCoachDetails } from "./JobCoachDetailsRepository";
import { updateJobCoachDetails as apiUpdateJobCoachDetails } from "./JobCoachDetailsRepository";
import { JobCoachDetailsFieldType, JobCoachDetailsFieldValueMap } from "./JobCoachDetailsField";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/StringUtils";
import { pushJobCoach } from "../../reducers/jobCoachDetilsPageSlice";

interface JobCoachDetailsModalProps<T extends JobCoachDetailsFieldType> {
  type: JobCoachDetailsFieldType;
  open: boolean;
  onClose: () => void;
  getUpdatedJobCoachDetails: (value: JobCoachDetailsFieldValueMap[T]) => UpdateJobCoachDetails | undefined;
  initialValue?: string;
}

const JobCoachDetailsModal = <T extends JobCoachDetailsFieldType>({
  open,
  onClose,
  getUpdatedJobCoachDetails,
  type,
  initialValue = "",
}: JobCoachDetailsModalProps<T>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<JobCoachDetailsFieldValueMap[T]>(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = async () => {
    try {
      const updatedJobCoachDetails = getUpdatedJobCoachDetails(value);
      if (!updatedJobCoachDetails) {
        return;
      }

      const apiUpdatedJobCoachDetails = await apiUpdateJobCoachDetails(updatedJobCoachDetails);
      dispatch(pushJobCoach(apiUpdatedJobCoachDetails));
      dispatch(showSnackbar({ message: t("jobCoachDetails.saveSuccess", { typeLabel: type }), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t(`jobCoachDetails.saveError`), severity: "error" }));
    } finally {
      onClose();
    }
  };

  const typeLabel = () => {
    switch (type) {
      case JobCoachDetailsFieldType.Email:
        return t("jobCoachDetails.emailLabel");
      case JobCoachDetailsFieldType.PhoneNumber:
        return t("jobCoachDetails.phoneNumberLabel");
      default:
        return "";
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && (typeof value === "string" ? value.trim() : value)) {
      handleSave();
      event.preventDefault();
    }
  };

  const renderSingleField = (label: string) => (
    <TextField
      inputRef={inputRef}
      autoFocus
      margin="dense"
      label={capitalizeFirstLetter(label)}
      type="text"
      fullWidth
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleKeyPress}
    />
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("jobCoachDetails.editLabel", { typeLabel: typeLabel() })}</DialogTitle>
      <DialogContent sx={{ width: "600px" }}>{renderSingleField(typeLabel())}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("generic.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t("jobCoachDetails.saveButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobCoachDetailsModal;
