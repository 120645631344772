import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  Paper,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setAnalyticsApplicants,
  setAnalyticsSelectedJobCoach,
  setAnalyticsSelectedOffice,
  setJobCoaches,
  setOffices,
} from "../../reducers/analyticsPageSlice";
import getApplicantAnalyticsItems, { fetchJobCoachItems, fetchOfficeItems } from "./AnalyticsRepository";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import { useTranslation } from "react-i18next";
import AnalyticsBarChartComponent from "./AnalyticsBarChartComponent";
import FilterSelectionComponent from "./FilterSelectionComponent";
import { TFunction } from "i18next";
import AnalyticsTableComponent from "./AnalyticsTableComponent";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { analyticsDataFields } from "./AnalyticsDataFields";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TableRows } from "@mui/icons-material";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";

export const AnalyticsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const offices = useAppSelector((state) => state.analyticsPageSlice.offices);
  const jobCoaches = useAppSelector((state) => state.analyticsPageSlice.jobCoaches);
  const fullApplicantList = useAppSelector((state) => state.analyticsPageSlice.applicants);
  const selectedOffice = useAppSelector((state) => state.analyticsPageSlice.selectedOffice);
  const selectedJobCoach = useAppSelector((state) => state.analyticsPageSlice.selectedJobCoach);
  const handleChange = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedColumn(event.target.value as string);
  }, []);

  const [selectedColumn, setSelectedColumn] = useState<string>("sfiLevel");
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState<{ [key: string]: string | null }>({});

  const handleFilterChange = useCallback((fieldName: string, value: string | null) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  }, []);

  const clearAllFilters = useCallback(() => {
    setFilters({});
    dispatch(setAnalyticsSelectedOffice(null));
    dispatch(setAnalyticsSelectedJobCoach(null));
  }, [dispatch]);

  const filteredApplicants = useMemo(() => {
    return fullApplicantList.filter((applicant) => {
      const matchesOffice = !selectedOffice || applicant.officeId === selectedOffice.id;
      const matchesJobCoach = !selectedJobCoach || applicant.jobCoachId === selectedJobCoach.id;
      const matchesFilters = analyticsDataFields.every((field) => {
        const selectedValue = filters[field.name];
        if (field.filter) {
          return field.filter(applicant, selectedValue as string);
        } else {
          return applicant;
        }
      });
      return matchesOffice && matchesJobCoach && matchesFilters;
    });
  }, [selectedOffice, selectedJobCoach, fullApplicantList, analyticsDataFields, filters]);

  useEffect(() => {
    if (selectedOffice && selectedJobCoach && selectedJobCoach.officeId !== selectedOffice.id) {
      dispatch(setAnalyticsSelectedJobCoach(null));
    }
  }, [selectedOffice, selectedJobCoach, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchJobCoachItems(), fetchOfficeItems(), getApplicantAnalyticsItems()])
      .then(([jobCoachData, officeData, applicantData]) => {
        dispatch(setJobCoaches(jobCoachData));
        dispatch(setOffices(officeData));
        dispatch(setAnalyticsApplicants(applicantData));
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: t("analyticsPage.failedToFetchData"),
            severity: "error",
          }),
        );
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, t]);

  const getData = useCallback(
    (t: TFunction) => {
      const fieldConfig = analyticsDataFields.find((field) => field.name === selectedColumn);
      if (!fieldConfig) return [];

      const groupedData: { [key: string]: number } = {};

      fieldConfig.options.forEach((option) => {
        groupedData[option] = 0;
      });

      filteredApplicants.forEach((applicant) => {
        const key = fieldConfig.groupBy(applicant);
        if (key in groupedData) {
          groupedData[key] += 1;
        } else {
          // Handle unexpected keys
          groupedData[key] = 1;
        }
      });

      return Object.keys(groupedData).map((key) => ({
        type: t(`analyticsPage.fields.${key}`, key),
        count: groupedData[key],
      }));
    },
    [filteredApplicants, selectedColumn, analyticsDataFields, t],
  );

  if (isLoading) {
    return <MaijaLoadingPage />;
  }

  return (
    <Paper sx={{ mb: 4, p: 2 }}>
      <ReportButtons />

      <FilterSelectionComponent
        offices={offices}
        jobCoaches={jobCoaches}
        selectedColumn={selectedColumn}
        handleChange={handleChange}
        filters={filters}
        handleFilterChange={handleFilterChange}
        clearAllFilters={clearAllFilters}
        t={t}
      />

      <AnalyticsBarChartComponent data={getData(t)} />

      <Accordion sx={{ mb: 40 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="filters-content" id="filters-header">
          <TableRows sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)", alignSelf: "center" }} />
          <Typography variant="h6">{t("analyticsPage.tableTitle")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AnalyticsTableComponent applicants={filteredApplicants} t={t} />
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

const ReportButtons: React.FC = () => {
  const { t } = useTranslation();

  const handleBusinessReportClick = () => {};
  const handleRegionalReportClick = () => {};
  const handleFilteredReportClick = () => {};

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-end"
      position="fixed"
      bottom="60px"
      right="60px"
      zIndex={1000}
      gap={2}
    >
      <Fab variant="extended" color="secondary" onClick={handleBusinessReportClick}>
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("analyticsPage.businessReport")}</Typography>
      </Fab>

      <Fab variant="extended" color="secondary" onClick={handleRegionalReportClick}>
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("analyticsPage.regionalReport")}</Typography>
      </Fab>

      <Fab variant="extended" color="secondary" onClick={handleFilteredReportClick}>
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("analyticsPage.filteredReport")}</Typography>
      </Fab>
    </Box>
  );
};

export default AnalyticsPage;
