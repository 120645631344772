import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Typography,
  TextField,
  Button,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from "@mui/material";
import { EvaluationCriteria, parseEvaluationCriteria } from "../../types/EvaluationCriteria";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import createApplicant from "./CreateApplicantRepository";
import { validateEmail, validatePlainText } from "../../utils/TextValidation";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { navigateToApplicantDetails } from "../../Routes";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { SecurityLevel } from "../../types/User";
import { fetchJobCoachItems } from "../job-coach-list/JobCoachListRepository";
import { setJobCoaches } from "../../reducers/jobCoachListPageSlice";
import { PersonAddAlt1, SupervisedUserCircle } from "@mui/icons-material";
import { setCompanyCreateSelectedJobCoach } from "../../reducers/companyCreatePageSlice";

export interface CreateApplicantFormProps {
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessAnimation: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateApplicantForm: React.FC<CreateApplicantFormProps> = ({ isLoading, setLoading, setSuccessAnimation }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();
  const loggedInUser = useAppSelector((state) => state.user.user);

  const [applicantFirstName, setApplicantFirstName] = useState("");
  const [applicantLastName, setApplicantLastName] = useState("");
  const [applicantFirstNameValid, setApplicantFirstNameValid] = useState(true);
  const [applicantLastNameValid, setApplicantLastNameValid] = useState(true);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [evaluationCriteria, setEvaluationCriteria] = useState<EvaluationCriteria | null>(null);
  const [evaluationCriteriaValid, setEvaluationCriteriaValid] = useState(true);
  const [lastDay, setLastDay] = useState(dayjs().add(6, "month"));
  const [lastDayValid, setLastDayValid] = useState(true);
  const jobCoaches = useAppSelector((state) => state.jobCoachListPageSlice.jobCoaches);
  const selectedJobCoach = useAppSelector((state) => state.companyCreatePageSlice.selectedJobCoach);
  const [selectedJobCoachValid, setSelectedJobCoachValid] = useState(true);

  useEffect(() => {
    if (loggedInUser?.securityLevel === SecurityLevel.CompanyAdmin) {
      fetchJobCoachItems().then((data) => dispatch(setJobCoaches(data)));
    }
  }, [loggedInUser]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    if (!emailValid) {
      setEmailValid(validateEmail(newEmail));
    }
    setEmail(newEmail);
  };

  const onCreateApplicantClick = () => {
    setApplicantFirstNameValid(validatePlainText(applicantFirstName));
    setApplicantLastNameValid(validatePlainText(applicantLastName));
    setEmailValid(validateEmail(email));
    setEvaluationCriteriaValid(evaluationCriteria !== null);
    setLastDayValid(lastDay.isValid());

    if (loggedInUser?.securityLevel === SecurityLevel.CompanyAdmin) {
      setSelectedJobCoachValid(selectedJobCoach !== null);
    }

    if (
      !validatePlainText(applicantFirstName) ||
      !validatePlainText(applicantLastName) ||
      !validateEmail(email) ||
      !evaluationCriteria ||
      !lastDay.isValid() ||
      (loggedInUser?.securityLevel === SecurityLevel.CompanyAdmin && !selectedJobCoach)
    ) {
      return;
    }

    setLoading(true);

    createApplicant(
      applicantFirstName,
      applicantLastName,
      email,
      evaluationCriteria as EvaluationCriteria,
      lastDay.toISOString(),
      selectedJobCoach ? selectedJobCoach.id : null,
    )
      .then((response) => {
        if (response) {
          setSuccessAnimation(true);
          setTimeout(() => {
            setSuccessAnimation(false);
            navigateToApplicantDetails(navigate, response.id);

            setEmail("");
            setApplicantFirstName("");
            setApplicantLastName("");
            setEvaluationCriteria(null);
            setLastDay(dayjs().add(6, "month"));
          }, 2000);
        } else {
          dispatch(
            showSnackbar({
              message: t("createApplicant.createApplicantError"),
              severity: "error",
            }),
          );
        }
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: t("createApplicant.createApplicantError"),
            severity: "error",
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ minHeight: "100%" }}>
      <PersonAddAlt1 style={{ fontSize: 200 }} />
      <Typography variant="h3" gutterBottom sx={{ mt: 2 }}>
        {t("createApplicant.title")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("createApplicant.instructions")}
      </Typography>
      <Box
        display="flex"
        alignContent="center"
        alignItems="flex-start"
        flexDirection="column"
        sx={{ width: "100%", maxWidth: isMobile ? "100%" : "400px" }}
      >
        {loggedInUser?.securityLevel === SecurityLevel.CompanyAdmin && (
          <Autocomplete
            sx={{ mt: 4 }}
            fullWidth
            options={jobCoaches}
            getOptionLabel={(option) => `${option.fullName}`}
            value={selectedJobCoach}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("createApplicant.selectJobCoach")}
                variant="outlined"
                helperText={!selectedJobCoachValid ? t("createApplicant.enterJobCoach") : ""}
                error={!selectedJobCoachValid}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <SupervisedUserCircle />
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={(_, newValue) => {
              if (!selectedJobCoachValid) {
                setSelectedJobCoachValid(newValue != null);
              }
              dispatch(setCompanyCreateSelectedJobCoach(newValue));
            }}
          />
        )}

        <TextField
          label={t("createApplicant.firstName")}
          variant="outlined"
          margin="normal"
          fullWidth
          sx={{ mt: 4 }}
          value={applicantFirstName}
          error={!applicantFirstNameValid}
          helperText={!applicantFirstNameValid ? t("createApplicant.enterFirstName") : ""}
          onChange={(e) => {
            if (!applicantFirstNameValid) {
              setApplicantFirstNameValid(validatePlainText(e.target.value));
            }
            setApplicantFirstName(e.target.value);
          }}
          data-cy="first-name"
        />

        <TextField
          label={t("createApplicant.lastName")}
          variant="outlined"
          margin="normal"
          fullWidth
          sx={{ mt: 4 }}
          value={applicantLastName}
          error={!applicantLastNameValid}
          helperText={!applicantLastNameValid ? t("createApplicant.enterLastName") : ""}
          onChange={(e) => {
            if (!applicantLastNameValid) {
              setApplicantLastNameValid(validatePlainText(e.target.value));
            }
            setApplicantLastName(e.target.value);
          }}
          data-cy="last-name"
        />

        <TextField
          label={t("createApplicant.email")}
          variant="outlined"
          margin="normal"
          fullWidth
          type="email"
          sx={{ mt: 4 }}
          value={email}
          error={!emailValid}
          helperText={!emailValid ? t("createApplicant.invalidEmail") : ""}
          onChange={handleEmailChange}
          data-cy="email"
        />

        <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ mt: 2 }}>
          <FormLabel component="legend" sx={{ mr: 2 }}>
            {t("createApplicant.evaluationCriteria")}
          </FormLabel>
          <RadioGroup
            aria-label="evaluationCriteria"
            name="evaluationCriteria"
            value={evaluationCriteria}
            onChange={(e) => {
              setEvaluationCriteria(parseEvaluationCriteria(e.target.value));
              setEvaluationCriteriaValid(true);
            }}
          >
            <Box display="flex" flexDirection="row">
              <FormControlLabel value={EvaluationCriteria.A} control={<Radio />} label="A" data-cy="evaluation-a" />
              <FormControlLabel value={EvaluationCriteria.B} control={<Radio />} label="B" data-cy="evaluation-b" />
              <FormControlLabel value={EvaluationCriteria.C} control={<Radio />} label="C" data-cy="evaluation-c" />
            </Box>
          </RadioGroup>
        </Box>
        {!evaluationCriteriaValid && (
          <Typography sx={{ mt: -2 }} color="error">
            {t("createApplicant.selectEvaluationCriteria")}
          </Typography>
        )}

        <Box sx={{ mt: 4, width: "100%" }} data-cy="date">
          <DatePicker
            label={t("createApplicant.lastDay")}
            value={lastDay}
            onChange={(date) => {
              setLastDay(date ?? dayjs(""));
              setLastDayValid((date ?? dayjs("")).isValid());
            }}
            format="YYYY-MM-DD"
            sx={{ width: "100%" }}
          />
          {!lastDayValid && (
            <Typography sx={{ mt: 1 }} color="error">
              {t("createApplicant.enterLastDay")}
            </Typography>
          )}
        </Box>
      </Box>

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 6, fontSize: "1.1rem" }}
        onClick={onCreateApplicantClick}
        disabled={isLoading}
        data-cy="submit"
      >
        {t("createApplicant.createApplicantButton")}
        {isLoading && <CircularProgress size={22} sx={{ ml: 3 }} />}
      </Button>
    </Box>
  );
};

export default CreateApplicantForm;
