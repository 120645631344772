import store from "../store";
import { clearLoggedInUser } from "../reducers/userSlice";
import { clearPlanningPage } from "../reducers/planningPageSlice";
import { clearApplicantDetailsPage } from "../reducers/applicantDetilsPageSlice";
import { clearApplicantListPage } from "../reducers/applicantListPageSlice";
import { clearJobCoachListPage } from "../reducers/jobCoachListPageSlice";
import { clearJobCoachDetailsPageSlice } from "../reducers/jobCoachDetilsPageSlice";
import { clearCompanyCreatePage } from "../reducers/companyCreatePageSlice";
import { clearTheme } from "../reducers/themeSlice";
import { clearAnalyticsPage } from "../reducers/analyticsPageSlice";

export function logout() {
  const dispatch = store.dispatch;
  dispatch(clearLoggedInUser());
  dispatch(clearAnalyticsPage());
  dispatch(clearApplicantDetailsPage());
  dispatch(clearApplicantListPage());
  dispatch(clearPlanningPage());
  dispatch(clearJobCoachListPage());
  dispatch(clearJobCoachDetailsPageSlice());
  dispatch(clearCompanyCreatePage());
  dispatch(clearTheme());
}
