import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "../hooks";
import MaijaRoute, {
  APPLICANT_LIST_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  JOB_COACH_DETAILS_ROUTE,
  navigateToJobCoachDetails,
  SETTINGS_ROUTE,
} from "../Routes";
import { useNavigate } from "react-router-dom";
import { logout } from "../utils/LogoutUtil";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Language, Menu as MenuIcon } from "@mui/icons-material";
import Flag from "react-world-flags";
import { ListItemIcon, ListItemText, Drawer, List, ListItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { RootState } from "../store";
import { SecurityLevel } from "../types/User";
import dayjs from "dayjs";

const languages = [
  { code: "sv", name: "Svenska", flag: "SE" },
  { code: "en", name: "English", flag: "GB" },
  /*{ code: "ar", name: "العربية", flag: "SA" },
  { code: "da", name: "Dansk", flag: "DK" },
  { code: "de", name: "Deutsch", flag: "DE" },
  { code: "fi", name: "Suomi", flag: "FI" },
  { code: "fr", name: "Français", flag: "FR" },
  { code: "ku", name: "Kurdî", flag: "IQ" },
  { code: "pl", name: "Polski", flag: "PL" },
  { code: "so", name: "Soomaali", flag: "SO" },
  { code: "ti", name: "ትግርኛ", flag: "ER" },
  { code: "vi", name: "Tiếng Việt", flag: "VN" },
  { code: "zh", name: "中文", flag: "CN" },
  { code: "no", name: "Norsk", flag: "NO" },
  { code: "fa", name: "فارسی", flag: "IR" },*/
];

const getContrastColor = (hexColor: string) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? "#000000" : "#FFFFFF"; // If luminance is high, return black; otherwise, white
};

interface MaijaAppBarProps {
  routes: MaijaRoute[];
}

const MaijaAppBar: React.FC<MaijaAppBarProps> = ({ routes }) => {
  const user = useAppSelector((state) => state.user.user);
  const logo = useAppSelector((state: RootState) => state.theme.logo);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const tertiaryColor = useAppSelector((state: RootState) => state.theme.tertiaryColor);
  const tertiaryContrastTextColor = getContrastColor(tertiaryColor);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isCompanyAdmin = user?.securityLevel === SecurityLevel.CompanyAdmin;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    Cookies.set("language", lng, { expires: 365 });
    dayjs.locale(lng);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || Cookies.get("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const settings = [
    { id: "settings", name: t("settings.settings") },
    { id: "change_password", name: t("settings.change_password") },
    { id: "logout", name: t("settings.logout") },
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: tertiaryColor, minWidth: "100%" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography
              style={{ userSelect: "none" }}
              key={"Logo"}
              variant="h3"
              noWrap
              component="a"
              onClick={() => {
                navigate(APPLICANT_LIST_ROUTE.path);
              }}
              data-cy="app-bar-route.logo"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "flex" },
                fontFamily: "Lato Web",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {logo ? (
                <img src={logo} alt="Logo" style={{ height: "40px", width: "auto" }} />
              ) : (
                <img
                  src="https://maija.blob.core.windows.net/company/MAIJA_V3.svg"
                  alt="Logo"
                  style={{ height: "40px", width: "auto" }}
                />
              )}
            </Typography>
            {!isMobile && (
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {routes.map((route) => (
                  <Button
                    key={route.path}
                    size="large"
                    onClick={() => {
                      if (route === JOB_COACH_DETAILS_ROUTE && user?.jobCoachId) {
                        navigateToJobCoachDetails(navigate, user?.jobCoachId);
                      } else {
                        navigate(route.path);
                      }
                    }}
                    data-cy={`app-bar-${route.nameKey}`}
                    sx={{ my: 2, color: tertiaryContrastTextColor, display: "block" }}
                  >
                    {t(route.nameKey)}
                  </Button>
                ))}
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Select language">
                <IconButton onClick={handleOpenLangMenu} sx={{ p: 0, mr: 2 }} data-cy="app-bar-route.language">
                  <Avatar>
                    <Language />
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-lang"
                anchorEl={anchorElLang}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                data-cy="app-bar-route.language-options"
                open={Boolean(anchorElLang)}
                onClose={handleCloseLangMenu}
              >
                {languages.map((lang) => (
                  <MenuItem
                    key={lang.code}
                    onClick={() => {
                      changeLanguage(lang.code);
                      handleCloseLangMenu();
                    }}
                    data-cy={`app-bar-route.language-${lang.code}`}
                  >
                    <ListItemIcon>
                      <Flag code={lang.flag} style={{ width: 24, height: 24 }} alt={lang.name} />
                    </ListItemIcon>
                    <ListItemText primary={lang.name} />
                  </MenuItem>
                ))}
              </Menu>

              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} data-cy="app-bar-route.settings">
                  <Avatar alt={user?.fullName} src={user?.profileIconUrl ?? "empty-text-to-trigger-alt"} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                data-cy="app-bar-route.settings-options"
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <Box key={setting.id}>
                    {!(setting.id === "settings" && !isCompanyAdmin) && (
                      <MenuItem
                        onClick={() => {
                          if (setting.id === "logout") {
                            logout();
                          } else if (setting.id === "change_password") {
                            navigate(CHANGE_PASSWORD_ROUTE.path);
                          } else if (setting.id === "settings") {
                            navigate(SETTINGS_ROUTE.path);
                          }
                          handleCloseUserMenu();
                        }}
                        data-cy={`app-bar-route.settings-${setting.id}`}
                      >
                        <Typography textAlign="center">{setting.name}</Typography>
                      </MenuItem>
                    )}
                  </Box>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
          <List>
            {routes.map((route) => (
              <ListItem button key={route.path} onClick={() => navigate(route.path)}>
                <ListItemText primary={t(route.nameKey)} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default MaijaAppBar;
