import { useEffect, useState, useMemo, useCallback } from "react";
import { fetchOfficeItems } from "./OfficeListRepository";
import { Box, Card, Typography, Button, Fab, Table, TableBody, TableContainer, Paper } from "@mui/material";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import AddIcon from "@mui/icons-material/Add";
import BusinessIcon from "@mui/icons-material/Business";
import { useNavigate } from "react-router-dom";
import { navigateToOfficeDetails } from "../../Routes";
import { COMPANY_CREATE_ROUTE } from "../../Routes";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setOffices } from "../../reducers/officeListPageSlice";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import MaijaContainer from "../../components/MaijaContainer";
import { setCompanyCreateSelectedTab } from "../../reducers/companyCreatePageSlice";
import { CompanyCreatePageTab } from "../company-create/CompanyCreatePage";
import { useTranslation } from "react-i18next";
import { MaijaTableHeader, MaijaTableRow, MaijaTableColumn } from "../../components/MaijaTable";

interface Office {
  id: string;
  name: string;
  numberJobCoaches: number;
  numberApplicants: number;
  numberJobsLast30Days: number;
}

const OfficesListPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const offices = useAppSelector((state) => state.officeListPageSlice.offices);

  const [sortFunction, setSortFunction] = useState<((a: Office, b: Office) => number) | null>(null); // eslint-disable-line

  useEffect(() => {
    setIsLoading(true);
    fetchOfficeItems()
      .then((data) => dispatch(setOffices(data)))
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  const handleSort = useCallback(
    (sortFunc: (a: Office, b: Office) => number) => {
      if (sortFunction === sortFunc) {
        const reversedSort = (a: Office, b: Office) => -sortFunc(a, b);
        setSortFunction(() => reversedSort);
      } else {
        setSortFunction(() => sortFunc);
      }
    },
    [sortFunction],
  );

  const columns: MaijaTableColumn<Office>[] = useMemo(
    () => [
      {
        key: "name",
        label: t("officesList.location"),
        onSort: (a, b) => a.name.localeCompare(b.name),
        renderCell: (office) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <BusinessIcon sx={{ mr: 1 }} />
            <Typography variant="body1">{office.name}</Typography>
          </Box>
        ),
        cellProps: {
          sx: { display: "flex", alignItems: "center" },
        },
      },
      {
        key: "numberJobCoaches",
        label: t("officesList.numberOfJobCoaches"),
        onSort: (a, b) => a.numberJobCoaches - b.numberJobCoaches,
      },
      {
        key: "numberApplicants",
        label: t("officesList.numberOfApplicants"),
        onSort: (a, b) => a.numberApplicants - b.numberApplicants,
      },
      {
        key: "numberJobsLast30Days",
        label: t("officesList.appliedJobsLast30Days"),
        onSort: (a, b) => a.numberJobsLast30Days - b.numberJobsLast30Days,
      },
    ],
    [t],
  );

  const sortedOffices = useMemo(() => {
    return sortFunction ? [...offices].sort(sortFunction) : offices;
  }, [offices, sortFunction]);

  if (isLoading && offices.length === 0) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaLoadingPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  if (offices.length === 0) {
    return (
      <MaijaContainer>
        <OfficesListEmptyState />
      </MaijaContainer>
    );
  }

  return (
    <MaijaContainer>
      <Fab
        variant="extended"
        color="secondary"
        style={{
          position: "fixed",
          bottom: "60px",
          right: "60px",
        }}
        onClick={() => {
          dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.OfficeTab));
          navigate(COMPANY_CREATE_ROUTE.path);
        }}
      >
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("officesList.addOffice")}</Typography>
      </Fab>
      <Typography variant="h4" gutterBottom sx={{ ml: 5 }}>
        {t("officesList.yourOffices")}
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 30, borderRadius: 2, overflow: "hidden" }}>
        <Table>
          <MaijaTableHeader columns={columns} handleSort={handleSort} />
          <TableBody>
            {sortedOffices.map((office) => (
              <MaijaTableRow
                key={office.id}
                item={office}
                columns={columns}
                onClick={() => navigateToOfficeDetails(navigate, office.id)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MaijaContainer>
  );
};

const OfficesListEmptyState = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreateOfficeButton = () => {
    dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.OfficeTab));
    navigate(COMPANY_CREATE_ROUTE.path);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Card sx={{ m: 15, p: 20 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <DomainAddIcon style={{ fontSize: 150 }} sx={{ mb: 5 }} />
          <Typography variant="h4" gutterBottom>
            {t("officesList.noOffices")}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleCreateOfficeButton} sx={{ m: 5 }}>
            {t("officesList.createOffice")}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default OfficesListPage;
