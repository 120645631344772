import React, { useEffect, useMemo, useState } from "react";
import { Typography, Box, Skeleton, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Work } from "@mui/icons-material";
import { useAppSelector } from "../../hooks";
import { TFunction } from "i18next";
import { PlatsbankenJobsCounts } from "../../types/PlatsbankenJobsCounts";
import { fetchPlatsbankenJobCounts } from "./ApplicantsDetailRepository";

interface PlatsbankenJobsCountAccordionProps {
  t: TFunction;
  applicantId: string;
}

const PlatsbankenJobsCountAccordion: React.FC<PlatsbankenJobsCountAccordionProps> = ({ t, applicantId }) => {
  const [jobCounts, setJobCounts] = useState<PlatsbankenJobsCounts | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const supportInfo = useAppSelector((state) => state.applicantDetilsPageSlice.supportInfoItemsMap[applicantId]);

  useEffect(() => {
    const fetchJobCounts = async () => {
      try {
        const counts = await fetchPlatsbankenJobCounts(applicantId);
        setJobCounts(counts);
        setIsLoading(false);
      } catch (error) {
        setError("Failed to fetch job counts.");
        setIsLoading(false);
      }
    };

    if (supportInfo?.firstPreferredJob || supportInfo?.secondPreferredJob || supportInfo?.thirdPreferredJob) {
      fetchJobCounts();
    }
  }, [supportInfo]);

  const totalJobsCount = useMemo(() => {
    return (
      (jobCounts?.firstPreferredJob || 0) + (jobCounts?.secondPreferredJob || 0) + (jobCounts?.thirdPreferredJob || 0)
    );
  }, [jobCounts]);

  const renderPreferredJob = (jobName: string | undefined | null, amount?: number) => {
    if (!jobName) return null;
    return (
      <Box key={jobName} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
          {jobName}:
        </Typography>
        {isLoading ? (
          <Skeleton variant="text" width={40} />
        ) : (
          <Typography variant="body1" fontWeight="bold">
            {amount}
          </Typography>
        )}
      </Box>
    );
  };

  if (
    supportInfo &&
    !supportInfo?.firstPreferredJob &&
    !supportInfo?.secondPreferredJob &&
    !supportInfo?.thirdPreferredJob
  ) {
    return null;
  }
  if (error) {
    return null;
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Work sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)", alignSelf: "center" }} />

        {isLoading ? (
          <Skeleton variant="text" width={200} height={40} />
        ) : (
          <Typography variant="h5">
            {t("applicantDetails.platsbankenJobs.title")}: {totalJobsCount}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {renderPreferredJob(supportInfo?.firstPreferredJob, jobCounts?.firstPreferredJob)}
          {renderPreferredJob(supportInfo?.secondPreferredJob, jobCounts?.secondPreferredJob)}
          {renderPreferredJob(supportInfo?.thirdPreferredJob, jobCounts?.thirdPreferredJob)}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PlatsbankenJobsCountAccordion;
