import { Box, Typography, Button, Card, CardContent } from "@mui/material";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { ApplicantReportListItem } from "../../types/ApplicantReport";
import { getApplicantReports } from "./ReportsRepository";
import { formatDateToString, formatDateTimeToString } from "../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { applicantDetailsPageSlice } from "../../reducers/applicantDetilsPageSlice";

interface ReportComponentProps {
  applicantId: string;
  onShowReport: (reportId: string) => void;
}

export const ReportsComponent: React.FC<ReportComponentProps> = ({ applicantId, onShowReport }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const reports = useAppSelector((state) =>
    applicantId ? state.applicantDetilsPageSlice.reportListItemsMap[applicantId] : [],
  );

  useEffect(() => {
    getApplicantReports(applicantId).then((data: ApplicantReportListItem[]) => {
      const sortedData = data.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      dispatch(applicantDetailsPageSlice.actions.setApplicantDetailsReportsList({ applicantId, reports: sortedData }));
    });
  }, [applicantId]);

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "auto",
          p: 2,
          "& > *": { my: 1 },
        }}
      >
        {reports?.map((report) => (
          <motion.div
            key={report.id}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Card sx={{ minWidth: 300, position: "relative" }}>
              <CardContent>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Box display="flex" flexDirection="column" alignItems="flex-start" mb={2}>
                    <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                      Rapport {formatDateToString(report.createdAt)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formatDateTimeToString(report.createdAt)}
                    </Typography>
                  </Box>

                  <Box display="flex" flexDirection="row" alignItems="center" sx={{ gap: 2 }}>
                    <Button variant="contained" color="primary" onClick={() => onShowReport(report.id)}>
                      {t("applicantDetails.report.showReport")}
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </Box>
    </Box>
  );
};
