import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { changePassword } from "./ChangePasswordRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import MaijaContainer from "../../components/MaijaContainer";
import { useTranslation } from "react-i18next";

const ChangePasswordPage: React.FC = () => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    if (!validatePasswords(oldPassword, newPassword)) {
      setIsLoading(false);
      dispatch(
        showSnackbar({
          message: t("changePassword.passwordTooShort"),
          severity: "error",
        }),
      );
      setNewPassword("");
      setOldPassword("");
      return;
    }

    changePassword(oldPassword, newPassword)
      .then(() => {
        dispatch(
          showSnackbar({
            message: t("changePassword.passwordUpdated"),
          }),
        );
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: t("generic.error"),
            severity: "error",
          }),
        );
      })
      .finally(() => {
        setIsLoading(false);
        setNewPassword("");
        setOldPassword("");
      });
  };

  return (
    <MaijaContainer
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Backdrop open={isLoading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress size={100} />
      </Backdrop>
      <Card sx={{ mt: 10 }}>
        <CardContent>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                {t("changePassword.title")}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  sx={{ mt: 1 }}
                  label={t("changePassword.currentPasswordLabel")}
                  type="password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  onChange={handleOldPasswordChange}
                  data-cy="current-password"
                />
                <TextField
                  sx={{ mt: 1 }}
                  label={t("changePassword.newPasswordLabel")}
                  type="password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  onChange={handleNewPasswordChange}
                  data-cy="new-password"
                />
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3 }}
                  onClick={handleSubmit}
                  data-cy="submit"
                >
                  {t("changePassword.submitButton")}
                </Button>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </MaijaContainer>
  );
};

export const validatePasswords = (oldPassword: string, newPassword: string) => {
  return oldPassword.length > 0 && newPassword.length >= 8;
};

export default ChangePasswordPage;
