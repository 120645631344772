import { JobCoachDetails } from "../../types/JobCoachDetails";
import axiosInstance from "../../utils/AxiosInstance";
import { StatusReport } from "../../types/StatusReport";
import { AxiosResponse } from "axios";

export async function getJobCoachDetails(jobCoachId: string): Promise<JobCoachDetails | undefined> {
  try {
    const response = await axiosInstance.get("/job_coach/details", {
      params: {
        job_coach_id: jobCoachId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateJobCoachDetails(data: UpdateJobCoachDetails): Promise<JobCoachDetails> {
  const response = await axiosInstance.put("/job_coach/details", data);
  return response.data;
}

export interface UpdateJobCoachDetails {
  companyId: string;
  jobCoachId: string;
  email?: string | null;
  phoneNumber?: string | null;
}

export const getJobcoachReport = async (jobCoachId: string): Promise<StatusReport> => {
  const response: AxiosResponse<StatusReport> = await axiosInstance.get("/job_coach/report", {
    params: {
      job_coach_id: jobCoachId,
    },
  });

  return response.data;
};

export async function sendEmail(jobCoachId: string, subject: string, body: string): Promise<boolean> {
  try {
    await axiosInstance.post("/job_coach/email", {
      job_coach_id: jobCoachId,
      email_subject: subject,
      email_body: body,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function removeJobCoach(jobCoachId: string): Promise<boolean> {
  try {
    await axiosInstance.post("/job_coach/delete", {
      job_coach_id: jobCoachId,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateProfilePicture(data: string, jobCoachId: string): Promise<string> {
  const response = await axiosInstance.post(`/job_coach_profile_picture`, {
    job_coach_id: jobCoachId,
    profile_picture: data,
  });
  return response.data.profileIconUrl;
}
