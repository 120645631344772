import React from "react";
import { Box } from "@mui/material";
import Lottie, { Options } from "react-lottie";

export interface MaijaLottieAnimationProps {
  animationData: any; // eslint-disable-line
  size?: number;
  show: boolean;
}

const MaijaLottieAnimation: React.FC<MaijaLottieAnimationProps> = ({ animationData, size, show }) => {
  const lottieOptions: Options = {
    loop: false,
    autoplay: true,
    animationData: animationData,
  };

  if (!show) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9998,
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <Lottie options={lottieOptions} height={size ?? 300} width={size ?? 300} />
    </Box>
  );
};

export default MaijaLottieAnimation;
