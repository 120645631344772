import React, { useCallback, useMemo, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { ApplicantAnalyticsItem } from "../../types/ApplicantAnalyticsItem";
import { TFunction } from "i18next";
import ClickableHeaderItem from "../../components/ClickableHeaderItem";
import { setAnalyticsApplicants } from "../../reducers/analyticsPageSlice";
import { useAppDispatch } from "../../hooks";
import AnalyticsTableItemComponent from "./AnalyticsTableItemComponent";

export interface AnalyticsTableProps {
  applicants: ApplicantAnalyticsItem[];
  t: TFunction;
}

const AnalyticsTableComponent: React.FC<AnalyticsTableProps> = ({ applicants, t }) => {
  const dispatch = useAppDispatch();

  const topScrollRef = useRef<HTMLDivElement>(null);
  const bottomScrollRef = useRef<HTMLDivElement>(null);

  const updateApplicantsList = useCallback(
    (updatedList: ApplicantAnalyticsItem[]) => {
      dispatch(setAnalyticsApplicants(updatedList));
    },
    [dispatch],
  );

  const sortedApplicants = useMemo(() => [...applicants], [applicants]);

  const handleSort = useCallback(
    (key: keyof ApplicantAnalyticsItem, isNumeric = false) => {
      const sortedList = [...sortedApplicants].sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];

        if (isNumeric) {
          return (Number(valueA) || 0) - (Number(valueB) || 0);
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
          return valueA.localeCompare(valueB);
        }

        if (valueA === valueB) return 0;
        return valueA ? -1 : 1;
      });

      updateApplicantsList(sortedList);
    },
    [sortedApplicants, updateApplicantsList],
  );

  const renderHeaderItem = useCallback(
    (text: string, key: keyof ApplicantAnalyticsItem, isNumeric: boolean = false) => (
      <Box width={250} p={1} key={key}>
        <ClickableHeaderItem text={text} onClick={() => handleSort(key, isNumeric)} />
      </Box>
    ),
    [handleSort],
  );

  const headerItems = useMemo(
    () => [
      renderHeaderItem(t("analyticsPage.fields.gender"), "gender"),
      renderHeaderItem(t("analyticsPage.fields.age"), "age"),
      renderHeaderItem(t("analyticsPage.fields.evaluationCriteria"), "evaluationCriteria"),
      renderHeaderItem(t("analyticsPage.fields.appliedJobsLast30Days"), "appliedJobsLast30Days", true),
      renderHeaderItem(t("analyticsPage.fields.nonAppliedJobsCount"), "nonAppliedJobsCount", true),
      renderHeaderItem(t("analyticsPage.fields.latestLogin"), "latestLogin"),
      renderHeaderItem(t("analyticsPage.fields.profileCompletion"), "profileCompletion", true),
      renderHeaderItem(t("analyticsPage.fields.educationLevel"), "educationLevel"),
      renderHeaderItem(t("analyticsPage.fields.sfiLevel"), "sfiLevel"),
      renderHeaderItem(t("analyticsPage.fields.canTakeWorkImmediately"), "canTakeWorkImmediately"),
    ],
    [renderHeaderItem, t],
  );

  const handleScroll = (ref: React.RefObject<HTMLDivElement>) => (e: React.UIEvent<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollLeft = e.currentTarget.scrollLeft;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (topScrollRef.current && bottomScrollRef.current) {
        bottomScrollRef.current.scrollLeft = topScrollRef.current.scrollLeft;
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const applicantList = useMemo(
    () => applicants.map((applicant) => <AnalyticsTableItemComponent key={applicant.id} applicant={applicant} t={t} />),
    [applicants, t],
  );

  return (
    <Box>
      <Box sx={{ overflowX: "auto" }} ref={topScrollRef} onScroll={handleScroll(bottomScrollRef)}>
        <Box
          minWidth="1500px"
          display="flex"
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 1,
          }}
        >
          {headerItems}
        </Box>
      </Box>
      <Box sx={{ overflowX: "auto" }} ref={bottomScrollRef} onScroll={handleScroll(topScrollRef)}>
        <Box minWidth="1500px">{applicantList}</Box>
      </Box>
    </Box>
  );
};

export default React.memo(AnalyticsTableComponent);
