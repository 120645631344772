import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Checkbox, FormControlLabel } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setDisplayedColumns } from "../../reducers/applicantListPageSlice";
import { useTranslation } from "react-i18next";
import { Settings } from "@mui/icons-material";

const columnOptions = [
  { label: "name", value: "fullName" },
  { label: "evaluationCriteria", value: "evaluationCriteria" },
  { label: "appliedJobs", value: "appliedJobsLast30Days" },
  { label: "nonAppliedJobs", value: "nonAppliedJobsCount" },
  { label: "latestLogin", value: "latestLogin" },
  { label: "profileCompletion", value: "profileCompletion" },
  { label: "educationLevel", value: "educationLevel" },
  { label: "sfiLevel", value: "sfiLevel" },
  { label: "canTakeWorkImmediately", value: "canTakeWorkImmediately" },
  { label: "jobCoachName", value: "jobCoachName" },
];

const ColumnSelectionButton = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const displayedColumns = useAppSelector((state) => state.applicantListPageSlice.displayedColumns);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleColumnChange = (value: string) => {
    const newDisplayedColumns = displayedColumns.includes(value)
      ? displayedColumns.filter((col) => col !== value)
      : [...displayedColumns, value];
    dispatch(setDisplayedColumns(newDisplayedColumns));
  };

  return (
    <>
      <IconButton color="secondary" onClick={(event) => setAnchorEl(event.currentTarget)}>
        <Settings sx={{ color: "#808080" }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {columnOptions.map((option) => (
          <MenuItem key={option.value}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={displayedColumns.includes(option.value)}
                  onChange={() => handleColumnChange(option.value)}
                />
              }
              label={t(`applicantsList.${option.label}`)}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ColumnSelectionButton;
