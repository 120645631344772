import userSlice from "./reducers/userSlice";
import snackbarSlice from "./reducers/snackbarSlice";
import planningPageSlice from "./reducers/planningPageSlice";
import applicantDetilsPageSlice from "./reducers/applicantDetilsPageSlice";
import applicantListPageSlice from "./reducers/applicantListPageSlice";
import jobCoachListPageSlice from "./reducers/jobCoachListPageSlice";
import jobCoachDetilsPageSlice from "./reducers/jobCoachDetilsPageSlice";
import officeListPageSlice from "./reducers/officeListPageSlice";
import officeDetilsPageSlice from "./reducers/officeDetailsPageSlice";
import companyCreatePageSlice from "./reducers/companyCreatePageSlice";
import themeReducer from "./reducers/themeSlice";
import analyticsPageSlice from "./reducers/analyticsPageSlice";

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    user: userSlice,
    planningPageSlice: planningPageSlice,
    applicantListPageSlice: applicantListPageSlice,
    applicantDetilsPageSlice: applicantDetilsPageSlice,
    jobCoachListPageSlice: jobCoachListPageSlice,
    jobCoachDetilsPageSlice: jobCoachDetilsPageSlice,
    officeListPageSlice: officeListPageSlice,
    officeDetailsPageSlice: officeDetilsPageSlice,
    companyCreatePageSlice: companyCreatePageSlice,
    snackbar: snackbarSlice,
    theme: themeReducer,
    analyticsPageSlice: analyticsPageSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export default store;
